import { Trans } from '@lingui/macro'
import { Trade } from '@uniswap/router-sdk'
import { Currency, CurrencyAmount, Percent, Token, TradeType } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import AddressInputPanel from 'components/AddressInputPanel'
import { ButtonError, ButtonPrimary } from 'components/Button'
import { GrayCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import SwapCurrencyInputPanel from 'components/CurrencyInputPanel/SwapCurrencyInputPanel'
import Loader from 'components/Icons/LoadingSpinner'
import { AutoRow } from 'components/Row'
import confirmPriceImpactWithoutFee from 'components/swap/confirmPriceImpactWithoutFee'
import ConfirmSwapModal from 'components/swap/ConfirmSwapModal'
import ConfirmWrapModal from 'components/swap/ConfirmWrapModal'
import PriceImpactWarning from 'components/swap/PriceImpactWarning'
import { ArrowWrapper, SwapCallbackError } from 'components/swap/styleds'
import SwapDetailsDropdown from 'components/swap/SwapDetailsDropdown'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import { SwitchLocaleLink } from 'components/SwitchLocaleLink'
import TokenSafetyModal from 'components/TokenSafety/TokenSafetyModal'
import { MouseoverTooltip } from 'components/Tooltip'
import { UNIVERSAL_ROUTER_ADDRESS } from 'constants/addresses';
import { isSupportedChain } from 'constants/chains'
import { TOKEN_SHORTHANDS } from 'constants/tokens'
import { useCurrency, useDefaultActiveTokens } from 'hooks/Tokens'
import { useIsSwapUnsupported } from 'hooks/useIsSwapUnsupported'
import usePermit2Allowance, { AllowanceState } from 'hooks/usePermit2Allowance'
import { useSwapCallback } from 'hooks/useSwapCallback'
import { useUSDPrice } from 'hooks/useUSDPrice'
import useWrapCallback, { WrapErrorText, WrapType } from 'hooks/useWrapCallback'
import JSBI from 'jsbi'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ArrowDown, Info } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { Text } from 'rebass'
import { InterfaceTrade, TradeState } from 'state/routing/types'
import { Field } from 'state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
} from 'state/swap/hooks'
import { useExpertModeManager } from 'state/user/hooks'
import styled, { useTheme } from 'styled-components/macro'
import { LinkStyledButton, ThemedText } from 'theme'
import invariant from 'tiny-invariant'
import { computeFiatValuePriceImpact } from 'utils/computeFiatValuePriceImpact'
import { currencyAmountToPreciseFloat, formatTransactionAmount } from 'utils/formatNumbers'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { computeRealizedPriceImpact, warningSeverity } from 'utils/prices'
import { supportedChainId } from 'utils/supportedChainId'
import { didUserReject } from 'utils/swapErrorToUserReadableMessage'

const ArrowContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  min-width: 40px;
  min-height: 40px;
  background: #1C1F23;
  border: 4px solid #000000;
  border-radius: 12px;
  :hover {
    svg > path {
      stroke: ${({ theme }) => theme.buttonPrimaryText}
    }
  }
`

const SwapSection = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.backgroundModule};
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.backgroundModule};
  padding: 16px;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;

  &:before {
    box-sizing: border-box;
    background-size: 100%;
    border-radius: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    content: '';
    border: none;
  }

  &:hover:before {
    border-color: ${({ theme }) => theme.stateOverlayHover};
  }

  &:has(.token-amount-input:focus) {
    background-color: black;
    color: white;
    border: 1px solid #57606B;
  }

  &:focus-within:before {
    border-color: ${({ theme }) => theme.stateOverlayPressed};
  }
`

const DetailsSwapSection = styled.div`
  position: relative;
  background-color: '#000000';
  border-radius: 10px;
  border: 1px solid #1C1F23;
  padding: 0px;
  color: ${({ theme }) => theme.textTertiary};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-top: 8px;
`

function largerPercentValue(a?: Percent, b?: Percent) {
  if (a && b) {
    return a.greaterThan(b) ? a : b
  } else if (a) {
    return a
  } else if (b) {
    return b
  }
  return undefined
}

export default function SwapForm({ 
  className,
  initialOutputCurrency 
}: { 
  className?: string;
  initialOutputCurrency?: Currency;
}) {
  const navigate = useNavigate()
  const { account, chainId, provider } = useWeb3React()
  const loadedUrlParams = useDefaultsFromURLSearch()
  const [newSwapQuoteNeedsLogging, setNewSwapQuoteNeedsLogging] = useState(true)
  const [fetchingSwapQuoteStartTime, setFetchingSwapQuoteStartTime] = useState<Date | undefined>()
  // const swapWidgetEnabled = useSwapWidgetEnabled()

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.[Field.INPUT]?.currencyId),
    useCurrency(loadedUrlParams?.[Field.OUTPUT]?.currencyId),
  ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c?.isToken ?? false) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useDefaultActiveTokens()
  const importTokensNotInDefault = useMemo(
    () =>
      urlLoadedTokens &&
      urlLoadedTokens
        .filter((token: Token) => {
          return !(token.address in defaultTokens)
        })
        .filter((token: Token) => {
          // Any token addresses that are loaded from the shorthands map do not need to show the import URL
          const supported = supportedChainId(chainId)
          if (!supported) return true
          return !Object.keys(TOKEN_SHORTHANDS).some((shorthand) => {
            const shorthandTokenAddress = TOKEN_SHORTHANDS[shorthand][supported]
            return shorthandTokenAddress && shorthandTokenAddress === token.address
          })
        }),
    [chainId, defaultTokens, urlLoadedTokens]
  )

  const theme = useTheme()

  // toggle wallet when disconnected
  const toggleWalletDrawer = useToggleAccountDrawer()

  // for expert mode
  const [isExpertMode] = useExpertModeManager()
  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const {
    trade: { state: tradeState, trade },
    allowedSlippage,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo()

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount,
          }
        : {
            [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
            [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
          },
    [independentField, parsedAmount, showWrap, trade]
  )
  const fiatValueInput = useUSDPrice(parsedAmounts[Field.INPUT]?.greaterThan(0) ? parsedAmounts[Field.INPUT] : undefined)
  const fiatValueOutput = useUSDPrice(parsedAmounts[Field.OUTPUT]?.greaterThan(0) ? parsedAmounts[Field.OUTPUT] : undefined)

  const [routeNotFound, routeIsLoading, routeIsSyncing] = useMemo(
    () => [!trade?.swaps, TradeState.LOADING === tradeState, TradeState.SYNCING === tradeState],
    [trade, tradeState]
  )

  const fiatValueTradeInput = useUSDPrice(trade?.inputAmount?.greaterThan(0) ? trade?.inputAmount : undefined)
  const fiatValueTradeOutput = useUSDPrice(trade?.outputAmount?.greaterThan(0) ? trade?.outputAmount : undefined)
  const stablecoinPriceImpact = useMemo(
    () =>
      routeIsSyncing || !trade
        ? undefined
        : computeFiatValuePriceImpact(fiatValueTradeInput.data, fiatValueTradeOutput.data),
    [fiatValueTradeInput, fiatValueTradeOutput, routeIsSyncing, trade]
  )

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // reset if they close warning without tokens in params
  const handleDismissTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
    navigate('/swap/')
  }, [navigate])

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade<Currency, Currency, TradeType> | undefined
    attemptingTxn: boolean
    swapErrorMessage: Error | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  })

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: showWrap
        ? parsedAmounts[independentField]?.toExact() ?? ''
        : formatTransactionAmount(currencyAmountToPreciseFloat(parsedAmounts[dependentField])),
    }),
    [dependentField, independentField, parsedAmounts, showWrap, typedValue]
  )

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )

  const maximumAmountIn = useMemo(() => {
    const maximumAmountIn = trade?.maximumAmountIn(allowedSlippage)
    return maximumAmountIn?.currency.isToken ? (maximumAmountIn as CurrencyAmount<Token>) : undefined
  }, [allowedSlippage, trade])
  const allowance = usePermit2Allowance(
    maximumAmountIn ??
      (parsedAmounts[Field.INPUT]?.currency.isToken
        ? (parsedAmounts[Field.INPUT] as CurrencyAmount<Token>)
        : undefined),
    isSupportedChain(chainId) ? UNIVERSAL_ROUTER_ADDRESS[chainId] : undefined
  )

  const maxInputAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyBalances[Field.INPUT]),
    [currencyBalances]
  )
  const showMaxButton = Boolean(maxInputAmount?.greaterThan(0) && !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount))
  const swapFiatValues = useMemo(() => {
    return { amountIn: fiatValueTradeInput.data, amountOut: fiatValueTradeOutput.data }
  }, [fiatValueTradeInput, fiatValueTradeOutput])

  // the callback to execute the swap
  const { callback: swapCallback } = useSwapCallback(
    trade,
    swapFiatValues,
    allowedSlippage,
    allowance.state === AllowanceState.ALLOWED ? allowance.permitSignature : undefined
  )

  const handleSwap = useCallback(() => {
    if (!swapCallback) {
      
      return
    }
    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapCallback()
      .then((hash) => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: hash })
      })
      .catch((error) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error,
          txHash: undefined,
        })
      })
  }, [
    swapCallback,
    tradeToConfirm,
    showConfirm,
  ])

  // errors
  const [swapQuoteReceivedDate, setSwapQuoteReceivedDate] = useState<Date | undefined>()

  // warnings on the greater of fiat value price impact and execution price impact
  const { priceImpactSeverity, largerPriceImpact } = useMemo(() => {
    const marketPriceImpact = trade?.priceImpact ? computeRealizedPriceImpact(trade) : undefined    
    const largerPriceImpact = largerPercentValue(marketPriceImpact, stablecoinPriceImpact)
    return { priceImpactSeverity: warningSeverity(largerPriceImpact), largerPriceImpact }
  }, [stablecoinPriceImpact, trade])

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {  
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])


  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toExact())
  }, [maxInputAmount, onUserInput])

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => onCurrencySelection(Field.OUTPUT, outputCurrency),
    [onCurrencySelection]
  )

  const swapIsUnsupported = useIsSwapUnsupported(currencies[Field.INPUT], currencies[Field.OUTPUT])

  const priceImpactTooHigh = priceImpactSeverity > 3 && !isExpertMode
  const showPriceImpactWarning = largerPriceImpact && priceImpactSeverity > 3

  // Handle time based logging events and event properties.
  useEffect(() => {
    const now = new Date()
    // If a trade exists, and we need to log the receipt of this new swap quote:
    if (newSwapQuoteNeedsLogging && !!trade) {
      // Set the current datetime as the time receipt of the latest swap quote.
      setSwapQuoteReceivedDate(now)
      // The Latest swap quote has just been logged, so we don't need to log the current trade anymore
      // unless user inputs change again and a new trade is in the process of being generated.
      setNewSwapQuoteNeedsLogging(false)
      // New quote is not being fetched, so set start time of quote fetch to undefined.
      setFetchingSwapQuoteStartTime(undefined)
    }
    // If another swap quote is being loaded based on changed user inputs:
    if (routeIsLoading) {
      setNewSwapQuoteNeedsLogging(true)
      if (!fetchingSwapQuoteStartTime) setFetchingSwapQuoteStartTime(now)
    }
  }, [
    newSwapQuoteNeedsLogging,
    routeIsSyncing,
    routeIsLoading,
    fetchingSwapQuoteStartTime,
    trade,
    setSwapQuoteReceivedDate,
  ])

  const showDetailsDropdown = Boolean(
    !showWrap && userHasSpecifiedInputOutput && (trade || routeIsLoading || routeIsSyncing)
  )  

  // Add new state for wrap modal
  const [{ showWrapConfirm, attemptingWrapTxn, wrapErrorMessage, wrapTxHash }, setWrapState] = useState<{
    showWrapConfirm: boolean
    attemptingWrapTxn: boolean
    wrapErrorMessage: string | undefined
    wrapTxHash: string | undefined
  }>({
    showWrapConfirm: false,
    attemptingWrapTxn: false,
    wrapErrorMessage: undefined,
    wrapTxHash: undefined,
  })

  // Update wrap handler
  const handleWrap = useCallback(() => {
    if (!onWrap) return
    
    setWrapState({
      showWrapConfirm: true,
      attemptingWrapTxn: true,
      wrapErrorMessage: undefined,
      wrapTxHash: undefined
    })

    onWrap()
      .then((hash) => {
        setWrapState({
          showWrapConfirm: true,
          attemptingWrapTxn: false,
          wrapErrorMessage: undefined,
          wrapTxHash: hash
        })
        onUserInput(Field.INPUT, '')
      })
      .catch((error) => {
        setWrapState({
          showWrapConfirm: true,
          attemptingWrapTxn: false,
          wrapErrorMessage: didUserReject(error) ? 'Transaction rejected' : error.message,
          wrapTxHash: undefined
        })
      })
  }, [onWrap, onUserInput])

  // Add handlers for wrap modal
  const handleWrapConfirmDismiss = useCallback(() => {
    setWrapState({
      showWrapConfirm: false,
      attemptingWrapTxn: false,
      wrapErrorMessage: undefined,
      wrapTxHash: undefined
    })
  }, [])

  // Add maxOutputAmount calculation similar to maxInputAmount
  const maxOutputAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyBalances[Field.OUTPUT]),
    [currencyBalances]
  )

  // Update showMaxButton logic for output field
  const showOutputMaxButton = Boolean(maxOutputAmount?.greaterThan(0) && !parsedAmounts[Field.OUTPUT]?.equalTo(maxOutputAmount))

  // Add handler for max output
  const handleMaxOutput = useCallback(() => {
    maxOutputAmount && onUserInput(Field.OUTPUT, maxOutputAmount.toExact())
  }, [maxOutputAmount, onUserInput])

  // Add new loading state for token switch
  const [isTokenSwitching, setIsTokenSwitching] = useState(false)

  // Modify the onSwitchTokens handler
  const handleSwitchTokens = useCallback(() => {
    setIsTokenSwitching(true) // Set loading state before switch
    onSwitchTokens()
    // Reset loading state after a short delay to allow new data to load
    setTimeout(() => {
      setIsTokenSwitching(false)
    }, 250)
  }, [onSwitchTokens])
  
  const clearSwapState = useCallback(() => {
    setSwapState(() => ({
      tradeToConfirm: trade,
      attemptingTxn: false,
      swapErrorMessage: undefined,
      showConfirm: false,
      txHash: undefined
    }))
  }, [])

  useEffect(() => {
    if (initialOutputCurrency) {
      onCurrencySelection(Field.OUTPUT, initialOutputCurrency)
    }
  }, [initialOutputCurrency, onCurrencySelection])

  return (
    <>
      <TokenSafetyModal
        isOpen={importTokensNotInDefault.length > 0 && !dismissTokenWarning}
        tokenAddress={importTokensNotInDefault[0]?.address}
        secondTokenAddress={importTokensNotInDefault[1]?.address}
        onContinue={handleConfirmTokenWarning}
        onCancel={handleDismissTokenWarning}
        showCancel={true}
      />
      {trade && showConfirm && (
          <ConfirmSwapModal
            isOpen={showConfirm}
            trade={trade}
            originalTrade={tradeToConfirm}
            onAcceptChanges={handleAcceptChanges}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            allowedSlippage={allowedSlippage}
            onConfirm={handleSwap}
            onDismiss={handleConfirmDismiss}
            swapQuoteReceivedDate={swapQuoteReceivedDate}
            allowance={allowance}
            clearSwapState={clearSwapState}
            swapError={swapErrorMessage?.message}
          />
        )}
          <div style={{ display: 'relative' }}>
            <SwapSection>
              <ThemedText.BodySecondary>Sell</ThemedText.BodySecondary>
              <SwapCurrencyInputPanel
                label={
                  independentField === Field.OUTPUT && !showWrap ? <Trans>From (at most)</Trans> : <Trans>From</Trans>
                }
                value={formattedAmounts[Field.INPUT]}
                showMaxButton={showMaxButton}
                currency={currencies[Field.INPUT] ?? null}
                onUserInput={handleTypeInput}
                onMax={handleMaxInput}
                fiatValue={
                  isTokenSwitching 
                    ? { data: undefined, isLoading: true }
                    : parsedAmounts[Field.INPUT]?.greaterThan(0) 
                      ? fiatValueInput 
                      : { data: undefined, isLoading: false }
                }
                onCurrencySelect={handleInputSelect}
                otherCurrency={currencies[Field.OUTPUT]}
                showCommonBases={true}
                loading={isTokenSwitching || (independentField === Field.OUTPUT && routeIsSyncing)}
                id="CURRENCY_INPUT_PANEL"
              />
            </SwapSection>
            <ArrowWrapper clickable={isSupportedChain(chainId)}>
              <ArrowContainer
                data-testid="swap-currency-button"
                onClick={handleSwitchTokens}
                color={theme.textPrimary}
              >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 3.33333V12.6667" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round"
                        strokeLinejoin="round"/>
                  <path d="M12.6667 8L8.00004 12.6667L3.33337 8" stroke="currentColor" strokeWidth="1.33333"
                        strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </ArrowContainer>
            </ArrowWrapper>
          </div>
          <AutoColumn gap="4px">
            <div>
              <SwapSection>
                <ThemedText.BodySecondary>Buy</ThemedText.BodySecondary>
                <SwapCurrencyInputPanel
                  value={formattedAmounts[Field.OUTPUT]}
                  onUserInput={handleTypeOutput}
                  label={
                    independentField === Field.INPUT && !showWrap ? <Trans>To (at least)</Trans> : <Trans>To</Trans>
                  }
                  showMaxButton={showOutputMaxButton}
                  onMax={handleMaxOutput}
                  hideBalance={false}
                  fiatValue={
                    isTokenSwitching
                      ? { data: undefined, isLoading: true }
                      : parsedAmounts[Field.OUTPUT]?.greaterThan(0)
                        ? fiatValueOutput
                        : { data: undefined, isLoading: false }
                  }
                  priceImpact={stablecoinPriceImpact}
                  currency={currencies[Field.OUTPUT] ?? null}
                  onCurrencySelect={handleOutputSelect}
                  otherCurrency={currencies[Field.INPUT]}
                  showCommonBases={true}
                  loading={isTokenSwitching || (independentField === Field.INPUT && routeIsSyncing)}
                  id="CURRENCY_OUTPUT_PANEL"
                />

                {recipient !== null && !showWrap ? (
                  <>
                    <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                      <ArrowWrapper clickable={false}>
                        <ArrowDown size="16" color={theme.textSecondary} />
                      </ArrowWrapper>
                      <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                        <Trans>- Remove recipient</Trans>
                      </LinkStyledButton>
                    </AutoRow>
                    <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                  </>
                ) : null}
              </SwapSection>
            </div>
            {showPriceImpactWarning && <PriceImpactWarning priceImpact={largerPriceImpact} />}
            <div>
              {swapIsUnsupported ? (
                <ButtonPrimary disabled={true}>
                  <ThemedText.DeprecatedMain mb="4px">
                    <Trans>Unsupported Asset</Trans>
                  </ThemedText.DeprecatedMain>
                </ButtonPrimary>
              ) : !account ? (
                <ButtonPrimary onClick={toggleWalletDrawer} fontWeight={600}>
                  <Trans>Connect Wallet</Trans>
                </ButtonPrimary>
              ) : showWrap ? (
                <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={handleWrap} fontWeight={600}>
                  {wrapInputError ? (
                    <WrapErrorText wrapInputError={wrapInputError} />
                  ) : wrapType === WrapType.WRAP ? (
                    <Trans>Wrap</Trans>
                  ) : wrapType === WrapType.UNWRAP ? (
                    <Trans>Unwrap</Trans>
                  ) : null}
                </ButtonPrimary>
              ) : routeNotFound && userHasSpecifiedInputOutput && !routeIsLoading && !routeIsSyncing ? (
                <GrayCard style={{ textAlign: 'center' }}>
                  <ThemedText.DeprecatedMain mb="4px">
                    <Trans>Insufficient liquidity for this trade.</Trans>
                  </ThemedText.DeprecatedMain>
                </GrayCard>
              ) : (
                <ButtonError
                  onClick={() => {
                    setSwapState({
                      tradeToConfirm: trade,
                      attemptingTxn: false,
                      swapErrorMessage: undefined,
                      showConfirm: true,
                      txHash: undefined
                    });
                  }}
                  id="swap-button"
                  disabled={
                    !isValid ||
                    routeIsSyncing ||
                    routeIsLoading ||
                    // priceImpactTooHigh ||
                    isTokenSwitching
                  }
                  error={isValid && priceImpactTooHigh}
                >
                  <Text fontSize={20} fontWeight={600}>
                    {isValid 
                    && priceImpactTooHigh
                     ? (
                      <Trans>High Price Impact</Trans>
                    ) : routeIsSyncing || routeIsLoading ? (
                      <Trans>Finalizing quote...</Trans>
                    ) : (
                      <Trans>Review</Trans>
                    )}
                  </Text>
                </ButtonError>
              )}
            </div>
          </AutoColumn>
      <SwitchLocaleLink />
      {showDetailsDropdown && (
        <DetailsSwapSection>
          <SwapDetailsDropdown
            trade={trade}
            syncing={routeIsSyncing}
            loading={routeIsLoading}
            allowedSlippage={allowedSlippage}
          />
        </DetailsSwapSection>
      )}

      {!swapIsUnsupported ? null : (
        <UnsupportedCurrencyFooter
          show={swapIsUnsupported}
          currencies={[currencies[Field.INPUT], currencies[Field.OUTPUT]]}
        />
      )}
      {showWrap && (        
        <ConfirmWrapModal
        isOpen={showWrapConfirm}
        onDismiss={handleWrapConfirmDismiss}
        attemptingTxn={attemptingWrapTxn}
        txHash={wrapTxHash}
        onConfirm={handleWrap}
        wrapErrorMessage={wrapErrorMessage}
        isWrap={wrapType === WrapType.WRAP}
        inputAmount={parsedAmounts[Field.INPUT]}
        fiatValue={fiatValueInput}
      />
      )}
    </>
  )
}
