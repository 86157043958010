import { SupportedChainId } from 'constants/chains'

type AddressMap = { [chainId: number]: string }

export const V2_FACTORY_ADDRESSES: AddressMap = {
  [SupportedChainId.HAUST]: '',
  [SupportedChainId.HAUST_TESTNET]: ''
}
export const V2_ROUTER_ADDRESS: AddressMap = {
  [SupportedChainId.HAUST]: '0x8C66B7138f4Fa795d7e61FFd29e661B8D22AbB32',
  [SupportedChainId.HAUST_TESTNET]: '0x8C66B7138f4Fa795d7e61FFd29e661B8D22AbB32',
}

export const PERMIT2_ADDRESS: AddressMap = {
  [SupportedChainId.HAUST]: '',
  [SupportedChainId.HAUST_TESTNET]: '0x000000000022D473030F116dDEE9F6B43aC78BA3',
}

// HAUST Testnet addresses
const HAUST_TESTNET_V3_CORE_FACTORY_ADDRESSES = '0x0290d31C5A04cF8C6dB8dF5ba7725118e4A67512'
const HAUST_TESTNET_V3_MIGRATOR_ADDRESSES = '0xA0E3f176012B18170581F9AE142723303104150e'
const HAUST_TESTNET_MULTICALL_ADDRESS = '0x7646AE4D47281Eb5b641E5ee8959Ac78781c8567'
const HAUST_TESTNET_QUOTER_ADDRESSES = '0x27cd86dd99c73c7F93Ca4407EFd17d7108fBDFA2'
const HAUST_TESTNET_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0x32E7EeC6be44Ff17e30237dF15eB8B3541138C94'
const HAUST_TESTNET_TICK_LENS_ADDRESSES = '0x423043f8C5F9e23BC1fd9f97977458f2Fd606B1f'
const HAUST_TESTNET_POOL_DEPLOYER = '0xbBf50bcbd1439385637FAE13bF1Be84F04C7366b'
const HAUST_TESTNET_UNIVERSAL_ROUTER_ADDRESS = '0xE71Af6EEd736bA513CbD19Ebc82D1Cf943b80b63'
const HAUST_TESTNET_V3_INIT_POOL_CODE_HASH = '0xe34f199b19b2b4f47f68442619d555527d244f78a3297ea89325f843f87b8b54'


// HAUST addresses
const BSC_V3_CORE_FACTORY_ADDRESSES = ''
const BSC_V3_MIGRATOR_ADDRESSES = ''
const BSC_MULTICALL_ADDRESS = ''
const BSC_QUOTER_ADDRESSES = ''
const BSC_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = ''
const BSC_TICK_LENS_ADDRESSES = ''
const BSC_POOL_DEPLOYER = ''
const BSC_UNIVERSAL_ROUTER_ADDRESS = ''

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  [SupportedChainId.HAUST_TESTNET]: HAUST_TESTNET_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.HAUST]: BSC_V3_CORE_FACTORY_ADDRESSES,
}

export const V3_MIGRATOR_ADDRESSES: AddressMap = {
  [SupportedChainId.HAUST_TESTNET]: HAUST_TESTNET_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.HAUST]: BSC_V3_MIGRATOR_ADDRESSES,
}

export const MULTICALL_ADDRESS: AddressMap = {
  [SupportedChainId.HAUST_TESTNET]: HAUST_TESTNET_MULTICALL_ADDRESS,
  [SupportedChainId.HAUST]: BSC_MULTICALL_ADDRESS,
}

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {}

export const QUOTER_ADDRESSES: AddressMap = {
  [SupportedChainId.HAUST_TESTNET]: HAUST_TESTNET_QUOTER_ADDRESSES,
  [SupportedChainId.HAUST]: BSC_QUOTER_ADDRESSES,
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  [SupportedChainId.HAUST_TESTNET]: HAUST_TESTNET_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.HAUST]: BSC_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {}

export const TICK_LENS_ADDRESSES: AddressMap = {
  [SupportedChainId.HAUST_TESTNET]: HAUST_TESTNET_TICK_LENS_ADDRESSES,
  [SupportedChainId.HAUST]: BSC_TICK_LENS_ADDRESSES,
}

export const POOL_DEPLOYER_ADDRESSES: AddressMap = {
  [SupportedChainId.HAUST_TESTNET]: HAUST_TESTNET_POOL_DEPLOYER,
  [SupportedChainId.HAUST]: BSC_POOL_DEPLOYER,
}

export const UNIVERSAL_ROUTER_ADDRESS: AddressMap = {
  [SupportedChainId.HAUST_TESTNET]: HAUST_TESTNET_UNIVERSAL_ROUTER_ADDRESS,
  [SupportedChainId.HAUST]: BSC_UNIVERSAL_ROUTER_ADDRESS,
}

export const V3_INIT_POOL_CODE_HASH: AddressMap = {
  [SupportedChainId.HAUST_TESTNET]: HAUST_TESTNET_V3_INIT_POOL_CODE_HASH
}
