import { Trans } from '@lingui/macro'
import { headlineMedium } from 'nft/css/common.css'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import { ButtonPrimary } from '../../../../components/Button'

const EmptyWalletContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  width: 100%;
`

const EmptyWalletText = styled(ThemedText.MediumHeader)`
  white-space: normal;
  margin-top: 12px;
  text-align: center;
`

const EmptyWalletSubtitle = styled(ThemedText.BodySmall)`
  white-space: normal;
  text-align: center;
  margin-top: 8px;
`

const ActionButton = styled(ButtonPrimary)`
  padding: 10px 24px;
  width: min-content;
  border: none;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  margin-top: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`

type EmptyWalletContent = {
  title?: React.ReactNode
  subtitle: React.ReactNode
  actionText?: React.ReactNode
  urlPath?: string
}
type EmptyWalletContentType = 'nft' | 'token' | 'activity' | 'pool' | 'chain'
const EMPTY_WALLET_CONTENT: { [key in EmptyWalletContentType]: EmptyWalletContent } = {
  nft: {
    title: <Trans>No NFTs yet</Trans>,
    subtitle: <Trans>Buy or transfer NFTs to this wallet to get started.</Trans>,
    actionText: <Trans>Explore NFTs</Trans>,
    urlPath: '/nfts',
  },
  token: {
    title: <Trans>No tokens yet</Trans>,
    subtitle: <Trans>Buy or transfer tokens to this wallet to get started.</Trans>,
  },
  activity: {
    title: <Trans>No activity yet</Trans>,
    subtitle: <Trans>Your onchain transactions and crypto purchases will appear here.</Trans>,
  },
  pool: {
    title: <Trans>No pools yet</Trans>,
    subtitle: <Trans>Open a new position or create a pool to get started.</Trans>,
    actionText: <Trans>+ New position</Trans>,
    urlPath: '/pool',
  },
  chain: {
    subtitle: <Trans>To use Haust DEX switch the network in your wallet's settings or profile.</Trans>,
    actionText: <Trans>Switch network</Trans>,
  }
}

interface EmptyWalletContentProps {
  type?: EmptyWalletContentType
  onNavigateClick?: () => void
  onSwitchNetwork?: () => void
}

const EmptyWalletContent = ({ type = 'nft', onNavigateClick, onSwitchNetwork }: EmptyWalletContentProps) => {
  const navigate = useNavigate()

  const content = EMPTY_WALLET_CONTENT[type]

  const actionButtonClick = useCallback(() => {
    if (type === 'chain' && onSwitchNetwork) {
      onSwitchNetwork()
    } else if (content.urlPath) {
      onNavigateClick?.()
      navigate(content.urlPath)
    }
  }, [content.urlPath, navigate, onNavigateClick, onSwitchNetwork, type])

  return (
    <>
      <EmptyWalletText className={headlineMedium}>{content.title}</EmptyWalletText>
      <EmptyWalletSubtitle color="textSecondary">{content.subtitle}</EmptyWalletSubtitle>
      {content.actionText && (
        <ActionButton data-testid="nft-explore-nfts-button" onClick={actionButtonClick}>
          {content.actionText}
        </ActionButton>
      )}
    </>
  )
}

export const EmptyWalletModule = (props?: EmptyWalletContentProps) => {
  return (
    <EmptyWalletContainer>
      <EmptyWalletContent {...props} />
    </EmptyWalletContainer>
  )
}
